
import { defineComponent, defineProps, onMounted, ref, watch, watchEffect, computed, onUnmounted } from "vue";
import { useStore } from "vuex";

// import ".././inspinia/css/titanium_wrapper.css";
// import "../../../public/titanium/inspinia/css/bootstrap.min.css";
// import "../../../public/titanium/inspinia/css/sb_extra.css";
// import "../../../public/titanium/inspinia/css/animate.css";
import "../../../public/titanium/inspinia/css/toastr.min.css";
// import "../../../public/titanium/inspinia/css/sb_overrides.css";
// import "../../../public/titanium/mainpage/style.css";

export default defineComponent({
  props: {
    titaniumPath: String,
    routeTime: Number,
  },
  setup(props) {
    const store = useStore();
    const loading = ref(false);
    const data = ref([]);
    const error = ref(null);
    const navTo = ref();
    const componentKey = computed(() => store.state.BodyModule.componentKey);

    const getPopupEl = () => {
      return document.getElementById("sb_popup") as HTMLElement;
    }

    const getModalDialog = () => {
      return getPopupEl().getElementsByClassName('modal-dialog') as HTMLCollectionOf<HTMLElement>;
    }

    const handlePopupClose = () => {
      // Makes modal close button cursor pointer
      const modalDialogEl = getModalDialog();
      modalDialogEl[0].style.pointerEvents = "auto";

      // add eventlistener
      const modalCloseBtnEl = getPopupEl().getElementsByClassName('modal-close') as HTMLCollectionOf<HTMLElement>;
      modalCloseBtnEl[0]
        .addEventListener("click", () => {
          window.SBU_PAGE.main._close_popup();
        });
    }

    const handlePopup = (path): void | boolean => {
      setTimeout(() => {
        console.log(path);
        // Check to if path includes popup string
        if (path && path.includes("!popup:")) {
          console.log("popup exists in url");
          // Set visibility and width of popup
          const popup = getPopupEl();
          popup.style.opacity = "1";
          const modalDialogEl = getModalDialog();
          console.log(modalDialogEl)
          modalDialogEl[0]!.style.maxWidth =
            "inherit";

          handlePopupClose();

          return true;
        }
        return false;
      }, 800);
    }

    const loadTitanium = (_path = "") => {

      error.value = null;
      loading.value = true;
      console.log("loadTitanium", _path);
      if (!handlePopup(_path)) {
        document.body.removeAttribute("data-bs-padding-right");
        document.body.removeAttribute("data-bs-overflow");
        document.body.style.overflow = "auto";
      }
      window.SBU_PAGE.main.navi_hash_change();
    }
    watchEffect(() => {
      if (componentKey.value > 0) {
        console.log('change')
        window.SBU_PAGE.main.navi_refresh()
      }
    })

    watchEffect(() => {
      loadTitanium(props.titaniumPath)
    })

    onMounted(() => {
      console.log("TIT", "mounted");
      loadTitanium();
    })

    onUnmounted(() => {
      // const modalCloseBtnEl = getPopupEl().getElementsByClassName('modal-close') as HTMLCollectionOf<HTMLElement>;
      // modalCloseBtnEl[0]
      //   .removeEventListener("click", () => {
      //     window.SBU_PAGE.main._close_popup();
      //   });
    });


    return {
      defineProps,
      onMounted,
      ref,
      watch,
      props,
      loading,
      data,
      error,
      getPopupEl,
      getModalDialog,
      handlePopupClose,
      handlePopup,
      loadTitanium,
      componentKey
    };
  },
});
